.welcome {
  padding-left: 30px;
  margin-top: 100px;
}

.homeContainer p {
  padding-left: 30px;
  padding-right: 35px;
  font-size: 20px;
  margin-top: 50px;
}
