body {
  margin: 0;
  background-image:
    linear-gradient(
      to right,
      rgb(195, 212, 218),
      rgb(169, 187, 222),
      rgb(35, 207, 204)
    );
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}
