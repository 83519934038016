.Calc {
  display: flex;
  overflow: hidden;
  padding-bottom: 5rem;
}

.Calc h1 {
  margin-top: 150px;
  padding-left: 80px;
  font-size: 30px;
}

.calculator {
  border: none;
  width: 50%;
  height: 65vh;
  margin-left: 20vw;
  margin-top: 150px;
}

.display {
  text-align: right;
  padding: 15px;
  background-color: gray;
  border: solid;
  border-color: rgb(223, 223, 223);
}

button {
  font-size: 20px;
  padding: 26px;
  cursor: pointer;
  padding-left: 75px;
  padding-right: 75px;
  transition: 0.4s;
  border: solid;
  border-color: rgb(223, 223, 223);
}

button:hover {
  opacity: 0.91;
}

.operators {
  float: right;
  display: flex;
  flex-direction: column;
}

.ope {
  background-color: rgb(240, 143, 31);
}

.digits {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(3, 1fr);
}

.zero {
  grid-column: 1/3;
  width: 375px;
}
